<template>
  <v-container class="pa-0">
    <v-row
      no-gutters
      class="ma-0 pa-0"
    >
      <!-- 모바일 화면 에서는 상단 인포가 안보이게 처리 -->
      <v-col
        cols="12"
        class="d-none d-sm-flex"
      >
        <v-alert
          border="bottom"
          color="info"
          outlined
          dense
          class="ma-0 "
        >
          <div class="text-body-2">
            <v-icon small color="info">info</v-icon>
            카테고리의 삭제는 <span class="error--text font-weight-bold">일괄합체</span>를 활용해 주십시오.
          </div>
          <div class="text-body-2">
            <v-icon small color="info">info</v-icon>
            수정시에는 해당 카테고리와 <span class="primary--text font-weight-bold">연계된 상위 정보</span>도 <span class="error--text font-weight-bold">동반수정</span>됩니다.
          </div>
          <div class="text-body-2">
            <v-icon small color="info">info</v-icon>
            순서의 변경은 해당 카테고리를 잡아서 이동시킨 후 <span class="primary--text font-weight-bold">순서변경</span> 버튼을 클릭하셔야 반영됩니다.
          </div>
        </v-alert>
        <!-- <v-divider></v-divider> -->
      </v-col>

      <v-col cols="12" class="pa-0 mt-1">

        <!-- 좌우로 분리되는 내용 시작 space-between -->
        <v-row
          no-gutters
          justify="space-between"
          class="ma-0 pa-0"
        >
          <!-- 좌측 카테고리 시작 -->
          <v-col cols="6" class="pr-1">
            <div class="text-subtitle-2 primary--text"><v-icon small color="primary">mdi-rhombus-split</v-icon> {{ title1 }}</div>
            <v-toolbar dense flat class="pa-0 ma-0">
              <v-btn :disabled="!orderChanged1" text color="primary" @click="orderChange1" class="pa-1 mr-2">
                <v-icon small left class="mr-0">mdi-cached</v-icon> 순서변경
              </v-btn>
              <v-btn text :color="allChecked1 ? 'warning' : 'success'" @click="allCheck1" class="pa-1 mr-2">
                <v-icon small left class="mr-0">
                  {{ allChecked1 ? 'mdi-checkbox-multiple-blank-outline' : 'mdi-check-box-multiple-outline' }}
                </v-icon> {{ allChecked1 ? '전체해제' : '전체선택' }}
              </v-btn>
              <v-btn  text color="indigo" @click="mergeCate1" class="pa-1">
                <v-icon small left class="mr-0">mdi-collapse-all</v-icon> 일괄합체
              </v-btn>
              <!-- <v-btn text color="error" @click="allRemove1" class="pa-1 mr-2">
                <v-icon small left class="mr-0">mdi-delete-forever-outline</v-icon> 일괄삭제
              </v-btn> -->
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>

            <div class="ma-3"></div>

            <v-card
              tile
              elevation="0"
              min-height="200"
            >
              <draggable
                :list="cates1"
                :disabled="!dndEnabled1"
                @start="dragging1 = true"
                @end="dragging1 = false"
                @update="orderChanged1 = true"
              >
                <v-row
                  v-for="(cate, i) in cates1"
                  :key="cate.id"
                >
                  <v-hover v-slot:default="{ hover }" transition="fade-transition">
                    <v-col class="my-2 ml-2 mr-12 pl-5 pr-10 py-0">
                      <v-chip
                        v-if="!cate.isEdit"
                        :color="(hover || cate.isChecked) ? 'light-blue lighten-5' : 'white'"
                        class="ml-1"
                      >
                        <v-icon
                          small
                          left
                          class="mr-2"
                          @click="cate.isChecked = !cate.isChecked"
                        >{{ cate.isChecked ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}</v-icon>
                        {{ cate.gubun1 }}
                        <v-icon
                          v-show="hover"
                          small
                          right
                          @click="setEdit1(cate, i)"
                          class="ml-5"
                        >edit</v-icon>
                        <!-- <v-icon
                          v-show="hover"
                          small
                          right
                          @click="remove1(cate, i)"
                          class="ml-3"
                        >mdi-trash-can-outline</v-icon> -->
                        <v-icon
                          v-show="cate.isClicked"
                          right
                          color="warning"
                          class="ml-10"
                        >mdi-arrow-right-circle</v-icon>
                      </v-chip>
                      <v-text-field
                        v-else
                        v-model.trim="edit1.gubun1"
                        :rules="[rules.required, rules.counter, rules.chkObverlab1]"
                        label=""
                        placeholder="입력하세요(최대 7자)"
                        counter="7"
                        maxlength="7"
                        append-icon="mdi-close-circle-outline"
                        @click:append="cate.isEdit = false; dndEnabled1 = true"
                        @keydown.esc="cate.isEdit = false; dndEnabled1 = true"
                        append-outer-icon="done"
                        @click:append-outer="editCate1(cate, i)"
                        @keypress.enter="editCate1(cate, i)"
                        @keypress="rmSpKeys"
                        @keyup="edit1.gubun1 = rmSpChars(edit1.gubun1)"
                      ></v-text-field>
                    </v-col>
                  </v-hover>
                </v-row>
              </draggable>

              <!-- 입력 텍스트필드 -->
              <v-row>
                <v-col id="inpArea1" class="mt-5 ml-3 mr-12 pl-5 pr-10">
                  <v-text-field
                    ref="txtAddCate1"
                    v-model.trim="form1.gubun1"
                    :rules="[rules.required, rules.counter, rules.chkObverlab1]"
                    label="등록"
                    placeholder="입력하세요(최대 7자)"
                    counter="7"
                    maxlength="7"
                    append-icon="mdi-close-circle-outline"
                    @click:append="init"
                    @keydown.esc="init"
                    append-outer-icon="done"
                    @click:append-outer="agree1()"
                    @keypress.enter="agree1()"
                    @keypress="rmSpKeys"
                    @keyup="form1.gubun1 = rmSpChars(form1.gubun1)"
                    @blur="$refs.txtAddCate1.resetValidation()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <!--// 우측 카테고리 끝 -->

          <!-- 가운데 -->
          <v-divider vertical></v-divider>

          <!-- 우측 카테고리 시작 -->
          <v-col class="pl-1">
            <!-- <div class="text-subtitle-2"><v-icon small>mdi-lumx</v-icon> 향후계획</div> -->
            <div class="text-subtitle-2 primary--text"><v-icon small color="primary">mdi-label-variant</v-icon> {{ title2 }}</div>
            <v-toolbar dense flat class="pa-0 ma-0">
              <v-btn :disabled="!orderChanged2" text color="primary" @click="orderChange2" class="pa-1 mr-2">
                <v-icon small left class="mr-0">mdi-cached</v-icon> 순서변경
              </v-btn>
              <v-btn text :color="allChecked2 ? 'warning' : 'success'" @click="allCheck2" class="pa-1 mr-2">
                <v-icon small left class="mr-0">
                  {{ allChecked2 ? 'mdi-checkbox-multiple-blank-outline' : 'mdi-check-box-multiple-outline' }}
                </v-icon> {{ allChecked2 ? '전체해제' : '전체선택' }}
              </v-btn>
              <v-btn  text color="indigo" @click="mergeCate2" class="pa-1">
                <v-icon small left class="mr-0">mdi-collapse-all</v-icon> 일괄합체
              </v-btn>
              <!-- <v-btn text color="error" @click="allRemove2" class="pa-1 mr-2">
                <v-icon small left class="mr-0">mdi-delete-forever-outline</v-icon> 일괄삭제
              </v-btn> -->
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>

            <div class="ma-3"></div>

            <v-card
              tile
              elevation="0"
              min-height="200"
            >
              <draggable
                :list="cates2"
                :disabled="!dndEnabled2"
                @start="dragging2 = true"
                @end="dragging2 = false"
                @update="orderChanged2 = true"
              >
                <v-row
                  v-for="(cate, i) in cates2"
                  :key="cate.id"
                >
                  <v-hover v-slot:default="{ hover }" transition="fade-transition">
                    <v-col class="my-2 ml-2 mr-12 pl-5 pr-10 py-0">
                      <v-chip
                        v-if="!cate.isEdit"
                        :color="(hover || cate.isChecked) ? 'light-blue lighten-5' : 'white'"
                        class="ml-1"
                      >
                        <v-icon
                          small
                          left
                          class="mr-2"
                          @click="cate.isChecked = !cate.isChecked"
                        >{{ cate.isChecked ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}</v-icon>
                        {{ cate.gubun1 }}
                        <v-icon
                          v-show="hover"
                          small
                          right
                          @click="setEdit2(cate, i)"
                          class="ml-5"
                        >edit</v-icon>
                        <!-- <v-icon
                          v-show="hover"
                          small
                          right
                          @click="remove2(cate, i)"
                          class="ml-3"
                        >mdi-trash-can-outline</v-icon> -->
                        <v-icon
                          v-show="cate.isClicked"
                          right
                          color="warning"
                          class="ml-10"
                        >mdi-arrow-right-circle</v-icon>
                      </v-chip>
                      <v-text-field
                        v-else
                        v-model.trim="edit2.gubun1"
                        :rules="[rules.required, rules.counter10, rules.chkObverlab2]"
                        label=""
                        :placeholder="typeNum2 === 19 ? '2000년_상반기(최대 10자)' : '입력하세요(최대 7자)'"
                        :counter="typeNum2 === 19 ? 10 : 7"
                        :maxlength="typeNum2 === 19 ? 10 : 7"
                        append-icon="mdi-close-circle-outline"
                        @click:append="cate.isEdit = false; dndEnabled2 = true"
                        @keydown.esc="cate.isEdit = false; dndEnabled2 = true"
                        append-outer-icon="done"
                        @click:append-outer="editCate2(cate, i)"
                        @keypress.enter="editCate2(cate, i)"
                        @keypress="rmSpKeys"
                        @keyup="edit2.gubun1 = rmSpChars(edit2.gubun1)"
                      ></v-text-field>
                    </v-col>
                  </v-hover>
                </v-row>
              </draggable>

              <!-- 입력 텍스트필드 -->
              <v-row>
                <v-col id="inpArea2" class="mt-5 ml-3 mr-12 pl-5 pr-10">
                  <v-text-field
                    ref="txtAddCate2"
                    v-model.trim="form2.gubun1"
                    :rules="[rules.required, rules.counter10, rules.chkObverlab2]"
                    label="등록"
                    :placeholder="typeNum2 === 19 ? '2000년_상반기(최대 10자)' : '입력하세요(최대 7자)'"
                    :counter="typeNum2 === 19 ? 10 : 7"
                    :maxlength="typeNum2 === 19 ? 10 : 7"
                    append-icon="mdi-close-circle-outline"
                    @click:append="init"
                    @keydown.esc="init"
                    append-outer-icon="done"
                    @click:append-outer="agree2()"
                    @keypress.enter="agree2()"
                    @keypress="rmSpKeys"
                    @keyup="form2.gubun1 = rmSpChars(form2.gubun1)"
                    @blur="$refs.txtAddCate2.resetValidation()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <!--// 좌측 카테고리 끝 -->
        </v-row>

      </v-col>
    </v-row>

    <merge-cate-dialog ref="mergeCateDialog"></merge-cate-dialog>

  </v-container>
</template>

<script>
import draggable from 'vuedraggable'
import { rmSpKeys, rmSpChars } from '@/lib/keyEvents'
import mergeCateDialog from '@/components/admin/MergeCateDialog'

export default {
  components: {
    draggable,
    mergeCateDialog
  },

  data: () => ({
    typeNum1: 0, // !! 타입번호1
    typeNum2: 0, // !! 타입번호2
    title1: '',
    title2: '',
    titles1: [
      { id: 5, title: '결과평가' },
      { id: 14, title: '직위' },
      { id: 18, title: '평가점수' }
    ],
    titles2: [
      { id: 6, title: '향후계획' },
      { id: 15, title: '자격' },
      { id: 19, title: '평가기간' }
    ],
    cates1: [],
    cates2: [],
    selectedCates1: [], // !! 선택한 카테고리 1
    selectedCates2: [], // !! 선택한 카테고리 2
    // !! 좌측 카테고리
    form1: {
      id: 0,
      num: 0, // 정렬구분자
      type1: 0,
      gubun1: '',
      isEdit: false,
      isChecked: false
    },
    edit1: {
      id: 0,
      num: 0, // 정렬구분자
      type1: 0,
      gubun1: '',
      isEdit: false,
      isChecked: false,
      sub: ''
    },
    oldIndex1: -1, // 기존 선택한 카테고리
    dndEnabled1: true, // dnd 사용여부
    dragging1: false, // dnd 중
    allChecked1: false, // 전체선택
    orderChanged1: false, // 부모의 순서변경여부
    // !! 우측 카테고리
    form2: {
      id: 0,
      num: 0, // 정렬구분자
      type1: 0,
      gubun1: '',
      isEdit: false,
      isChecked: false
    },
    edit2: {
      id: 0,
      num: 0, // 정렬구분자
      type1: 0,
      gubun1: '',
      isEdit: false,
      isChecked: false,
      sub: ''
    },
    oldIndex2: -1, // 기존 선택한 카테고리
    dndEnabled2: true, // dnd 사용여부
    dragging2: false, // dnd 중
    allChecked2: false, // 전체선택
    orderChanged2: false // 부모의 순서변경여부
  }),

  computed: {
    // data 에서는 this를 쓸 수 없으므로 computed 에서 룰을 만든다
    rules () {
      return {
        required: value => !!value || '입력값은 필수 정보입니다',
        counter: value => value.length <= 7 || '입력값은 최대 7글자이어야 합니다',
        counter10: value => {
          if (this.typeNum2 === 19) {
            return value.length <= 10 || '입력값은 최대 10글자이어야 합니다'
          } else {
            return value.length <= 7 || '입력값은 최대 7글자이어야 합니다'
          }
        },
        chkObverlab1: value => {
          const gubuns = this.cates1.map(s => s.gubun1)
          if (gubuns.includes(value)) return '이미 등록된 카테고리입니다'
          else return true
        },
        chkObverlab2: value => {
          const gubuns = this.cates2.map(s => s.gubun1)
          if (gubuns.includes(value)) return '이미 등록된 카테고리입니다'
          else return true
        }
      }
    }
  },

  watch: {
    '$route' (to, from) {
      // this.$router.push(to.path)

      // 중요: 넘어온 타입 번호를 매칭시켜야 한다!
      this.typeNum1 = parseInt(this.$route.params.type1, 10)
      this.typeNum2 = parseInt(this.$route.params.type2, 10)

      // 제목 패칭
      this.title1 = this.titles1.find(t => t.id === this.typeNum1).title
      this.title2 = this.titles2.find(t => t.id === this.typeNum2).title

      // 리스트 패칭 1
      this.getDataFromApi1()
        .then(data => {
          if (data.items && data.items.length > 0) this.cates1 = data.items
          else this.cates1 = []
        })

      // 리스트 패칭 2
      this.getDataFromApi2()
        .then(data => {
          if (data.items && data.items.length > 0) this.cates2 = data.items
          else this.cates2 = []
        })

      // 폼 초기화
      this.init()
    }
  },

  mounted () {
    if (!this.$store.state.ui.dbcode) {
      this.redirect('/')
    } else if (!(this.$store.state.ui.lv === 'M' || this.$store.state.ui.lv === 'G')) {
      // 참고: router.js 에서 이미 체크했지만.. 관리(대행)자가 아니면 .. 기본화면으로 돌려보낸다
      this.redirect('/case')
    }

    // 중요: 넘어온 타입 번호를 매칭시켜야 한다!
    this.typeNum1 = parseInt(this.$route.params.type1, 10)
    this.typeNum2 = parseInt(this.$route.params.type2, 10)

    // 제목 패칭
    this.title1 = this.titles1.find(t => t.id === this.typeNum1).title
    this.title2 = this.titles2.find(t => t.id === this.typeNum2).title

    // 리스트 패칭 1
    this.getDataFromApi1()
      .then(data => {
        if (data.items && data.items.length > 0) this.cates1 = data.items
        else this.cates1 = []
      })

    // 리스트 패칭 2
    this.getDataFromApi2()
      .then(data => {
        if (data.items && data.items.length > 0) this.cates2 = data.items
        else this.cates2 = []
      })

    // 폼 초기화
    this.init()
  },

  methods: {
    // 중요: 공통함수 --
    rmSpKeys, // keypress 특수문자 입력 막기
    rmSpChars, // keyup, down 특수문자 제거
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    redirect (to = '') {
      this.$router.push(to)
    },
    // 참고: 초기화 함수
    init () {
      // 등록validation 초기화
      this.$refs.txtAddCate1.resetValidation()
      this.$refs.txtAddCate2.resetValidation()

      // !! 좌측 카테고리
      this.form1.id = 0
      this.form1.num = 0
      this.form1.type1 = this.typeNum1 // 타입
      this.form1.gubun1 = ''
      this.form1.isEdit = false
      this.form1.isChecked = false

      this.edit1.id = 0
      this.edit1.num = 0
      this.edit1.type1 = this.typeNum1 // 타입
      this.edit1.gubun1 = ''
      this.edit1.isEdit = false
      this.edit1.isChecked = false

      this.oldIndex1 = 0 // 기존 선택요소를 초기화
      this.dndEnabled1 = true // 드래그 앤 드랍 사용가능하게
      this.orderChanged1 = false // 순서변경여부 초기화
      this.dragging1 = false // dnd 중
      this.allChecked1 = false // 전체선택

      // !! 우측 카테고리
      this.form2.id = 0
      this.form2.num = 0
      this.form2.type1 = this.typeNum2 // 타입
      this.form2.gubun1 = ''
      this.form2.isEdit = false
      this.form2.isChecked = false

      this.edit2.id = 0
      this.edit2.num = 0
      this.edit2.type1 = this.typeNum2 // 타입
      this.edit2.gubun1 = ''
      this.edit2.isEdit = false
      this.edit2.isChecked = false

      this.oldIndex2 = 0 // 기존 선택요소를 초기화
      this.dndEnabled2 = true // 드래그 앤 드랍 사용가능하게
      this.orderChanged2 = false // 순서변경여부 초기화
      this.dragging2 = false // dnd 중
      this.allChecked2 = false // 전체선택

      this.selectedCates1 = [] // !! 선택 카테고리 1 초기화
      this.selectedCates2 = [] // !! 선택 카테고리 2 초기화
    },
    // !! 데이터 패칭 1
    async getDataFromApi1 () {
      const { data } = await this.$axios.get(`admin/cate/getType/${this.typeNum1}`)
      if (!data.success) throw new Error(`list error: ${data.message}`)
      return { items: data.cates }
    },
    // !! 데이터 패칭 2
    async getDataFromApi2 () {
      const { data } = await this.$axios.get(`admin/cate/getType/${this.typeNum2}`)
      if (!data.success) throw new Error(`list error: ${data.message}`)
      return { items: data.cates }
    },
    // !! 리스트 리프레시 함수 1
    async getList1 () {
      try {
        const { items } = await this.getDataFromApi1()
        if (items && items.length > 0) {
          this.cates1 = items
          return items.length
        } else {
          this.cates1 = []
          return 0
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // !! 리스트 리프레시 함수 2
    async getList2 () {
      try {
        const { items } = await this.getDataFromApi2()
        if (items && items.length > 0) {
          this.cates2 = items
          return items.length
        } else {
          this.cates2 = []
          return 0
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 중요: 좌측 카테고리 ----
    async agree1 () {
      try {
        // 최종 입력값 검증
        if (!this.$refs.txtAddCate1.validate()) throw new Error('입력값을 확인해 주세요')
        // 입력값 비어있는지 체크
        if (!this.form1.gubun1) throw new Error('입력값을 확인해 주세요')
        // 중복체크
        if (!this.chkObverlab1(this.form1.gubun1)) throw new Error('이미 등록된 카테고리입니다')

        // num 의 가장 큰값을 만들어준다.
        let maxNum = 0
        if (this.cates1.length > 0) {
          maxNum = Math.max(...(this.cates1.map(s => s.num)))
        }
        this.form1.num = maxNum + 1
        this.form1.type1 = this.typeNum1 // !! 타입을 다시 맞춰주는게 중요!

        // DB 처리
        const { data } = await this.$axios.post('admin/cate/addCate', this.form1)
        if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
        // refresh
        const dataLength = await this.getList1()
        if (dataLength > 0) {
          this.init() // 초기화 하고
          this.goToInput1() // 스크롤 내리기
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // edit 선택
    async setEdit1 (cate, index) {
      if (this.edit1.gubun1) { // 기존 선택한게 있으면 초기화
        this.cates1[this.oldIndex1].isEdit = false
      }

      this.oldIndex1 = index
      cate.isEdit = true
      this.edit1.gubun1 = cate.gubun1
      this.edit1.id = cate.id
      this.edit1.num = cate.num
      this.edit1.isEdit = true // 편집중으로 표시
      this.edit1.isChecked = cate.isChecked

      this.dndEnabled1 = false // 선택중엔 드래그앤 드랍 금지
    },
    // 선택편집
    async editCate1 (cate, index) {
      try {
        // 입력값 비어있는지 체크
        if (!this.edit1.gubun1) throw new Error('입력값을 확인해 주세요')
        // 중복체크
        if (!this.chkObverlab1(this.edit1.gubun1)) throw new Error('이미 등록된 카테고리입니다')

        // !! 편집하는 카테고리의 num,sub 을 edit 폼과 맞춰야 한다!
        this.edit1.num = cate.num
        this.edit1.sub = cate.sub
        // DB 처리
        const { data } = await this.$axios.post('admin/cate/cateUpdate', this.edit1)
        if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
        // refresh
        await this.getList1()
        this.init() // 초기화 하고
        cate.isEdit = false // 편집창 닫고
        this.dndEnabled1 = true // 편집 끝나면 다시 드래그 가능하게 한다
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 좌측 카테고리 중복체크 함수
    chkObverlab1 (value) {
      if (this.cates1 || this.cates1.length > 0) {
        const gubuns = this.cates1.map(s => s.gubun1)
        if (gubuns.includes(value)) {
          return false
        } else {
          return true
        }
      }
    },
    // 리스트 순서를 변경하도록 하는 메서드
    async orderChange1 () {
      try {
        if (this.orderChanged1) { // 순서가 변경된 경우만..
          this.orderChanged1 = false // 순서변경 여부 초기화

          const ids = this.cates1.map(s => s.id) // id 배열을 따로 만든다.
          // DB 처리
          const { data } = await this.$axios.post('admin/cate/changeOrder', { ids })
          if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
          // refresh
          await this.getList1()
          this.init() // 초기화
        } else {
          throw new Error('카테고리 순서를 먼저 변경해 주세요')
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 리스트 체크박스 전체 선택/해제
    allCheck1 () {
      if (this.cates1.length > 0) {
        if (!this.allChecked1) {
          this.cates1.forEach(c => { c.isChecked = true })
          this.allChecked1 = true
        } else {
          this.cates1.forEach(c => { c.isChecked = false })
          this.allChecked1 = false
        }
      }
    },
    // 중요: 일괄합체 1
    async mergeCate1 () {
      try {
        // 체크된 갯수가 2개 이상이어야 의미가 있다
        if (this.checkedLength1() < 2) throw new Error(`합체할 2개 이상의 카테고리를 선택하시기 바랍니다`)
        if (await this.$refs.mergeCateDialog.open('카테고리', { width: 550 }, this.typeNum1, this.selectedCates1)) {
          // refresh
          await this.getList1()
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 체크된 갯수 리턴 1
    checkedLength1 () {
      this.selectedCates1 = [] // 초기화
      let cnt = 0
      this.cates1.forEach(c => {
        if (c.isChecked === true) {
          this.selectedCates1.push(c) // 선택한 카테고리 배열에 넣어준다.
          cnt++
        }
      })
      return cnt
    },
    // 선택삭제
    // async remove1 (cate, index) {
    //   try {
    //     const msg = `삭제시에는 해당 카테고리와 연계된 상위 정보도 동반삭제되므로 신중을 기하십시오.`
    //     if (await this.$refs.cd0923.open('삭제', msg, { color: 'error', width: 380 })) {
    //       // 카테고리 삭제
    //       this.cates1.splice(index, 1)
    //       // 넘어온 cate.id 를 넘겨서 지워주면 된다.
    //       const { data } = await this.$axios.post('admin/cate/deleteCate', { selectedIds: cate.id })
    //       if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
    //       // refresh
    //       await this.getDataFromApi1()
    //       this.init()
    //     }
    //   } catch (e) {
    //     this.sbpop(e)
    //   }
    // },
    // 일괄삭제
    // async allRemove1 () {
    //   try {
    //     let selectedIds = [] // 선택한 아이디만
    //     for (let i = 0; i < this.cates1.length; i++) {
    //       if (this.cates1[i].isChecked) {
    //         selectedIds.push(this.cates1[i].id)
    //       }
    //     }
    //     if (selectedIds.length <= 0) throw new Error('삭제할 카테고리를 선택하십시오')
    //     const msg = `삭제시에는 해당 카테고리와 연계된 상위 정보도 동반삭제되므로 신중을 기하십시오.`
    //     if (await this.$refs.cd0923.open('삭제', msg, { color: 'error', width: 380 })) {
    //       // 선택한 카테고리를 순회하면서 지워준다
    //       // !! 차후 DB에서 삭제하고 리스트를 다시 패칭하면 되므로 아래는 사실 필요없다.
    //       for (let i = 0; i < selectedIds.length; i++) {
    //         // id로 인덱스를 찾고 지운다
    //         const index = this.cates1.findIndex(c => c.id === selectedIds[i])
    //         this.cates1.splice(index, 1)
    //       }
    //       // selectedIds 배열을 넘겨서 지우면 된다.
    //       const { data } = await this.$axios.post('admin/cate/deleteCate', { selectedIds })
    //       if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
    //       // refresh
    //       await this.getDataFromApi1()
    //       this.init()
    //     }
    //   } catch (e) {
    //     this.sbpop(e)
    //   }
    // },
    // 중요: 우측 카테고리 -----
    async agree2 () {
      try {
        // 최종 입력값 검증
        if (!this.$refs.txtAddCate2.validate()) throw new Error('입력값을 확인해 주세요')
        // 입력값 비어있는지 체크
        if (!this.form2.gubun1) throw new Error('입력값을 확인해 주세요')
        // 중복체크
        if (!this.chkObverlab2(this.form2.gubun1)) throw new Error('이미 등록된 카테고리입니다')

        // num 의 가장 큰값을 만들어준다.
        let maxNum = 0
        if (this.cates2.length > 0) {
          maxNum = Math.max(...(this.cates2.map(s => s.num)))
        }
        this.form2.num = maxNum + 1
        this.form2.type1 = this.typeNum2 // !! 타입을 다시 맞춰주는게 중요!

        // DB 처리
        const { data } = await this.$axios.post('admin/cate/addCate', this.form2)
        if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
        // refresh
        const dataLength = await this.getList2()
        if (dataLength > 0) {
          this.init() // 초기화 하고
          this.goToInput2() // 스크롤 내리기
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // edit 선택
    async setEdit2 (cate, index) {
      if (this.edit2.gubun1) { // 기존 선택한게 있으면 초기화
        this.cates2[this.oldIndex2].isEdit = false
      }

      this.oldIndex2 = index
      cate.isEdit = true
      this.edit2.gubun1 = cate.gubun1
      this.edit2.id = cate.id
      this.edit2.num = cate.num
      this.edit2.isEdit = true // 편집중으로 표시
      this.edit2.isChecked = cate.isChecked

      this.dndEnabled2 = false // 선택중엔 드래그앤 드랍 금지
    },
    // 선택편집
    async editCate2 (cate, index) {
      try {
        // 입력값 비어있는지 체크
        if (!this.edit2.gubun1) throw new Error('입력값을 확인해 주세요')
        // 중복체크
        if (!this.chkObverlab2(this.edit2.gubun1)) throw new Error('이미 등록된 카테고리입니다')

        // !! 편집하는 카테고리의 num,sub 을 edit 폼과 맞춰야 한다!
        this.edit2.num = cate.num
        this.edit2.sub = cate.sub
        // DB 처리
        const { data } = await this.$axios.post('admin/cate/cateUpdate', this.edit2)
        if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
        // refresh
        await this.getList2()
        this.init() // 초기화 하고
        cate.isEdit = false // 편집창 닫고
        this.dndEnabled2 = true // 편집 끝나면 다시 드래그 가능하게 한다
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 우측 카테고리 중복체크 함수
    chkObverlab2 (value) {
      if (this.cates2 || this.cates2.length > 0) {
        const gubuns = this.cates2.map(s => s.gubun1)
        if (gubuns.includes(value)) return false
        else return true
      }
    },
    // 리스트 순서를 변경하도록 하는 메서드
    async orderChange2 () {
      try {
        if (this.orderChanged2) { // 순서가 변경된 경우만..
          this.orderChanged2 = false // 순서변경 여부 초기화

          const ids = this.cates2.map(s => s.id) // id 배열을 따로 만든다.
          // DB 처리
          const { data } = await this.$axios.post('admin/cate/changeOrder', { ids })
          if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
          // refresh
          await this.getList2()
          this.init() // 초기화
        } else {
          throw new Error('카테고리 순서를 먼저 변경해 주세요')
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 리스트 체크박스 전체 선택/해제
    allCheck2 () {
      if (this.cates2.length > 0) {
        if (!this.allChecked2) {
          this.cates2.forEach(c => { c.isChecked = true })
          this.allChecked2 = true
        } else {
          this.cates2.forEach(c => { c.isChecked = false })
          this.allChecked2 = false
        }
      }
    },
    // 중요: 일괄합체 2
    async mergeCate2 () {
      try {
        // 체크된 갯수가 2개 이상이어야 의미가 있다
        if (this.checkedLength2() < 2) throw new Error(`합체할 2개 이상의 카테고리를 선택하시기 바랍니다`)
        if (await this.$refs.mergeCateDialog.open('카테고리', { width: 550 }, this.typeNum2, this.selectedCates2)) {
          // refresh
          await this.getList2()
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 체크된 갯수 리턴 2
    checkedLength2 () {
      this.selectedCates2 = [] // 초기화
      let cnt = 0
      this.cates2.forEach(c => {
        if (c.isChecked === true) {
          this.selectedCates2.push(c) // 선택한 카테고리 배열에 넣어준다.
          cnt++
        }
      })
      return cnt
    },
    // 선택삭제
    // async remove2 (cate, index) {
    //   try {
    //     const msg = `삭제시에는 해당 카테고리와 연계된 상위 정보도 동반삭제되므로 신중을 기하십시오.`
    //     if (await this.$refs.cd0923.open('삭제', msg, { color: 'error', width: 380 })) {
    //       // 카테고리 삭제
    //       this.cates2.splice(index, 1)
    //       // 넘어온 cate.id 를 넘겨서 지워주면 된다.
    //       const { data } = await this.$axios.post('admin/cate/deleteCate', { selectedIds: cate.id })
    //       if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
    //       // refresh
    //       await this.getDataFromApi1()
    //       this.init()
    //     }
    //   } catch (e) {
    //     this.sbpop(e)
    //   }
    // },
    // 일괄삭제
    // async allRemove2 () {
    //   try {
    //     let selectedIds = [] // 선택한 아이디만
    //     for (let i = 0; i < this.cates2.length; i++) {
    //       if (this.cates2[i].isChecked) {
    //         selectedIds.push(this.cates2[i].id)
    //       }
    //     }
    //     if (selectedIds.length <= 0) throw new Error('삭제할 카테고리를 선택하십시오')
    //     const msg = `삭제시에는 해당 카테고리와 연계된 상위 정보도 동반삭제되므로 신중을 기하십시오.`
    //     if (await this.$refs.cd0923.open('삭제', msg, { color: 'error', width: 380 })) {
    //       // 선택한 카테고리를 순회하면서 지워준다
    //       for (let i = 0; i < selectedIds.length; i++) {
    //         // id로 인덱스를 찾고 지운다
    //         const index = this.cates2.findIndex(c => c.id === selectedIds[i])
    //         this.cates2.splice(index, 1)
    //       }
    //       // selectedIds 배열을 넘겨서 지우면 된다.
    //       const { data } = await this.$axios.post('admin/cate/deleteCate', { selectedIds })
    //       if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)
    //       // refresh
    //       await this.getDataFromApi2()
    //       this.init()
    //     }
    //   } catch (e) {
    //     this.sbpop(e)
    //   }
    // },
    // 중요: 기타 -----
    // 등록폼으로 스크롤을 내리는 함수 - 좌측
    goToInput1 () {
      this.$vuetify.goTo('#inpArea1', { duration: 200, offset: -100, easing: 'linear' })
      this.$refs.txtAddCate1.focus()
    },
    // 등록폼으로 스크롤을 내리는 함수 - 우측
    goToInput2 () {
      this.$vuetify.goTo('#inpArea2', { duration: 200, offset: -100, easing: 'linear' })
      this.$refs.txtAddCate2.focus()
    }
  }
}
</script>
